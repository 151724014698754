import { useCallback, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { callEndpoint, Method } from "./axios";

export default function useEmailChange(): {
  onSubmit: (params: { email: string; password: string }) => void;
  submitting: boolean;
  errorMessage: string;
  success: boolean;
} {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [redirectUrl] = useQueryParam("redirectUrl", StringParam);

  const onSubmit = useCallback(
    async (params: { email: string; password: string }) => {
      const { email, password } = params;
      setSubmitting(true);
      setErrorMessage("");
      try {
        await callEndpoint(Method.POST, "/api/request-email-change", {
          email,
          password,
          redirectUrl,
        });
        setSuccess(true);
      } catch (e: unknown) {
        setErrorMessage((e as Error).message || "Something went wrong.");
      }
      setSubmitting(false);
    },
    [setSubmitting, redirectUrl],
  );

  return {
    submitting,
    errorMessage,
    success,
    onSubmit,
  };
}
