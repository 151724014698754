/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { Box, jsx } from "theme-ui";

import Section from "../Section";

export default function EmailSent(): React.ReactElement {
  return (
    <Box>
      <Section centered={true}>
        <DUIText size="2xl" marginBottom="12px">
          Email Sent!
        </DUIText>
        <DUIText size="xl">
          We've sent an email to the requested email address with a link to
          complete the change.
        </DUIText>
      </Section>
    </Box>
  );
}
