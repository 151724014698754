/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { Box, jsx } from "theme-ui";

import useEmailChange from "../../http/useEmailChange";
import Section from "../Section";
import ChangeEmailForm from "./ChangeEmailForm";
import EmailSent from "./EmailSent";
import PasswordRequired from "./PasswordRequired";

export default function ChangeEmail(props: {
  hasPassword: boolean;
}): React.ReactElement {
  const { onSubmit, submitting, errorMessage, success } = useEmailChange();

  let Content: React.ReactElement;
  if (!props.hasPassword) {
    Content = <PasswordRequired />;
  } else if (success) {
    Content = <EmailSent />;
  } else {
    Content = (
      <ChangeEmailForm
        errorMessage={errorMessage}
        submitting={submitting}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <div>
      <Box>
        <Section centered={true}>
          <DUIText size="2xl">Change Email Address</DUIText>
        </Section>
        {Content}
      </Box>
    </div>
  );
}
