/** @jsx jsx */
import { ReactElement, useEffect, useState } from "react";
import { jsx } from "theme-ui";

import ChangeEmail from "../components/ChangeEmail/ChangeEmail";
import MasterLayout from "../components/MasterLayout";
import { getUser, User } from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

export default function ChangeEmailPage(): ReactElement {
  const [user, setUser] = useState(Loading.inProgress<User | undefined>());
  const waitForLoad = useWaitForLoad();

  useEffect(() => {
    waitForLoad(getUser(), setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  const notLoggedIn = !user.isLoading && !user.value;

  useEffect(() => {
    if (notLoggedIn) {
      window.location.replace("/?redirectUrl=/change-email");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [user]);

  return (
    <MasterLayout title="Change Email">
      {user.value && <ChangeEmail hasPassword={user.value.hasPassword} />}
    </MasterLayout>
  );
}
