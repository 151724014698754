/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import { useCallback, useState } from "react";
import { jsx } from "theme-ui";

import DUIInputLabel from "../DUIInputLabel";
import Section from "../Section";
import { fullWidthSx } from "../styles";
import ValidatingForm from "../ValidatingForm";

export default function ChangeEmailForm(props: {
  onSubmit: (params: { email: string; password: string }) => void;
  errorMessage?: string;
  submitting: boolean;
}): React.ReactElement {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = useCallback(() => {
    props.onSubmit({ email, password });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [props.onSubmit, email, password]);

  return (
    <Section shaded={true}>
      <ValidatingForm onSubmitPreventDefault={handleSubmit}>
        <DUIInputLabel text="For security, re-enter your password">
          <DUIInput
            type="password"
            placeholder="••••••••"
            required={true}
            minLength={6}
            autoComplete="password"
            value={password}
            onValueChange={setPassword}
          />
        </DUIInputLabel>
        <DUIInputLabel text="New Email Address" mt={5}>
          <DUIInput
            type="email"
            placeholder="gavin@hooli.com"
            autoComplete="off"
            required={true}
            value={email}
            onValueChange={setEmail}
          />
        </DUIInputLabel>
        <DUIButton
          mt={9}
          sx={fullWidthSx}
          size="lg"
          type="submit"
          disabled={props.submitting}
        >
          Send Confirmation
        </DUIButton>
        {props.errorMessage && (
          <DUIText size="lg" mt={3} sx={{ color: "danger.core" }}>
            {props.errorMessage}
          </DUIText>
        )}
      </ValidatingForm>
    </Section>
  );
}
