/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { Link } from "gatsby";
import { Box, jsx } from "theme-ui";

import Section from "../Section";

export default function PasswordRequired(): React.ReactElement {
  return (
    <Box>
      <Section centered={true}>
        <DUIText size="xl" marginBottom="12px">
          Before changing your email address you must{" "}
          <Link to="/reset-password">set a password</Link>.
        </DUIText>
      </Section>
    </Box>
  );
}
